import React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";

const NotFoundPage = () => (
  <Layout>
    <div
      style={{
        minHeight: "calc(100vh - 64px)",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        className="content center slide-up"
        style={{
          margin: "0 auto",
          padding: "4rem 0",
        }}
      >
        <h1 className="is-size-1 is-size-3-mobile is-size-1-tablet has-text-centered">
          404
        </h1>
        <p className="is-size-4 is-size-7-mobile is-size-4-tablet has-text-centered">
          The Page you're looking for was not found.
        </p>
        <Link to="/" className="button is-primary contact-me">
          Go Back
        </Link>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
